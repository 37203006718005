.registration-container {
    width: 100%;
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .registration-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .registration-form .ant-form-item {
    margin-bottom: 15px;
  }
  
  .submit-button {
    width: 100%;
    background-color: #1890ff;
    color: white;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #40a9ff;
  }
  
  .ant-form-item-label {
    font-size: 14px;
    font-weight: bold;
    color: #555;
  }
  
  input::placeholder {
    font-style: italic;
  }
  