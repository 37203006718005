.event-page {
    padding: 20px;
    background-color: #ffffff;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
  }
  
  .banner {
    height: 300px;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  
  .banner-actions {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .banner-actions button {
    margin: 0 5px;
    padding: 10px 15px;
    background-color: #6200ea;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  h1 {
    margin-top: 20px;
    font-size: 2rem;
  }
  
  .registered-users {
    margin-top: 20px;
  }
  
  .user-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }
  
  .user-actions button {
    margin-right: 10px;
    background-color: #6200ea;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
  
  .add-vip-btn {
    background-color: #6200ea;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-bottom: 15px;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .add-vip-btn:hover {
    background-color: #3700b3;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1002;
  }
  
  .modal-content {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .modal h3 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #6200ea;
  }
  
  .modal input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 1rem;
    transition: border-color 0.3s;
  }
  
  .modal input:focus {
    border-color: #6200ea;
    outline: none;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-actions .btn-primary {
    background-color: #6200ea;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .modal-actions .btn-primary:hover {
    background-color: #3700b3;
  }
  
  .modal-actions .btn-secondary {
    background-color: #ddd;
    color: #333;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s;
  }
  
  .modal-actions .btn-secondary:hover {
    background-color: #bbb;
  }

  .table-responsive {
    width: 100%;
    overflow-x: auto;
  }
  .duplicate-row {
    background-color: #ffcccc; /* Light red color for duplicate rows */
  }
  .qr-code-button-container {
    position: absolute;
    top: 20px; /* Adjust to your preference */
    right: 20px; /* Adjust to your preference */
    z-index: 1000; /* Ensure it's on top of other elements */
  }
  @media (max-width: 768px) {
    .table-responsive {
      font-size: 12px;
    }
  
    .event-page h1 {
      font-size: 1.5rem;
    }
  }
  