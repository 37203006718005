/* Sidebar expanded */
.sidebar {
    width: 250px;
    height: 100vh;
    background-color: #6200ea;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    transition: width 0.3s ease;
    z-index: 1000;
    overflow: hidden;
  }
  
  /* Sidebar collapsed */
  .sidebar.closed {
    width: 80px; /* Collapsed width */
  }
  
  /* Sidebar header */
  .sidebar-header {
    padding: 15px;
    display: flex;
    justify-content: flex-end;
  }
  
  /* Menu list */
  .menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  /* Menu items */
  .menu-list li {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align items to the start when expanded */
    transition: all 0.3s ease;
  }
  .sidebar.closed .sidebar-header {
    justify-content: center;
  }
  /* Hide text when sidebar is collapsed */
  .sidebar.closed .menu-list li {
    justify-content: center; /* Center icons when sidebar is collapsed */
  }
  
  /* Hide text when sidebar is collapsed */
  .sidebar.closed .menu-list li span {
    display: none; /* Hide text in collapsed mode */
  }
  
  /* Menu icons */
  .menu-list li a {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  
  /* Adjust icon size and margin */
  .menu-icon {
    font-size: 20px;
    margin-right: 15px;
    transition: margin 0.3s ease; /* Smooth transition for margin */
  }
  
  /* Remove margin-right when sidebar is collapsed */
  .sidebar.closed .menu-icon {
    margin-right: 0;
  }
  