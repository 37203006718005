.success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height */
    text-align: center;
    background-color: #f9f9f9;
    padding: 20px;
  }
  
  .success-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #4caf50; /* Green for success */
  }
  
  .success-container p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 40px;
  }
  
  .back-button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  