.event-dashboard {
    padding: 20px;
    background-color: #ffffff;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
  }
  
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .header-actions {
    display: flex;
    align-items: center;
  }
  
  .search-bar {
    padding: 10px;
    border-radius: 25px;
    border: 1px solid #ddd;
    outline: none;
    width: 250px;
    margin-right: 15px;
    transition: all 0.3s;
  }
  
  .search-bar:focus {
    border-color: #6200ea;
  }
  
  .add-event-btn {
    background-color: #6200ea;
    color: white;
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .add-event-btn:hover {
    background-color: #3700b3;
    transform: translateY(-3px);
  }
  
  /* Event Cards */
  .events-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust minimum width */
    justify-content: start; /* Align cards to the left */
    gap: 20px; /* Maintain some gap between cards */
  }
  
  .event-card {
    background-color: #ffffff;
    border-radius: 15px; /* Round the top corners */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 0; /* Remove padding to ensure no gaps around the thumbnail */
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
    max-width: 300px; /* Limit the width of each card */
  }
  
  .event-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  .event-thumbnail {
    width: 100%;
    height: 225px; /* Adjust the height to fit the recommended size */
    object-fit: cover; /* Ensures the image covers the entire area without distortion */
    border-top-left-radius: 15px;
    border-top-right-radius: 15px; /* Rounded corners for the top */
  }
  
  .event-info {
    padding: 10px;
  }
  
  .event-name {
    font-size: 1.25rem;
    color: #6200ea;
    margin-bottom: 5px;
  }
  
  .event-date,
  .event-location {
    color: #4a4a4a;
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
  
  /* Modal */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .event-dashboard .modal {
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    display: block;
    position: relative;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .modal-title {
    font-size: 1.5rem;
    color: #6200ea;
  }
  
  .close-modal-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 8px;
    border: 1px solid #ddd;
    font-size: 1rem;
    transition: border-color 0.3s;
  }
  
  .input-field:focus {
    border-color: #6200ea;
  }
  
  /* Checkbox styling */
  .checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .checkbox-container input {
    margin-right: 10px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  
  .checkbox-container label {
    font-size: 1rem;
    color: #6200ea;
  }
  
  /* Buttons */
  .btn-primary {
    background-color: #6200ea;
    color: white;
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .btn-primary:hover {
    background-color: #3700b3;
    transform: translateY(-2px);
  }
  
  .btn-secondary {
    background-color: #ddd;
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    font-size: 1rem;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .btn-secondary:hover {
    background-color: #bbb;
    transform: translateY(-2px);
  }
  
  .rdrStaticRanges {
    display: none; /* Hides the left panel */
  }
  
  .rdrDefinedRangesWrapper {
    display: none; /* Remove the left panel completely */
  }
  
  /* Make the date range picker take full width */
  .rdrDateRangeWrapper {
    justify-content: center; /* Centers the calendar */
    width: 100%; /* Full width for the calendar */
  }

  .thumbnail-upload {
    margin-bottom: 15px;
  }
  
  .thumbnail-label {
    display: block;
    margin-bottom: 5px;
    color: #6200ea;
    font-size: 0.9rem;
  }
  
  .thumbnail-preview {
    margin-top: 10px;
  }
  
  .thumbnail-preview img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  .rounds-input {
    margin-top: 20px;
    border-top: 2px solid #ddd;
    padding-top: 20px;
  }
  
  .rounds-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #6200ea;
    margin-bottom: 10px;
  }
  
  .round-date-container {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    background: #f9f9f9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .date-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .date-label {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
  }
  
  .all-day-toggle {
    display: inline-flex; /* Ensures the checkbox and label are on the same line */
    align-items: center; /* Vertically aligns the checkbox and label */
    white-space: nowrap; 
  }
  
  .all-day-toggle label {
    margin: 0; /* Removes any default margin */
    font-size: 0.9rem;
    color: #333;
    
  }
  
  .all-day-toggle input {
    margin-right: 8px;
    margin-top: 13px;
    cursor: pointer;
  }
  
  .round-times {
    margin-top: 10px;
  }
  
  .time-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .time-input {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-right: 10px;
    flex-grow: 1;
    font-size: 0.9rem;
  }
  
  .time-input:focus {
    border-color: #6200ea;
  }
  
  .remove-round-btn {
    background-color: #e53935;
    color: #fff;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s;
  }
  
  .remove-round-btn:hover {
    background-color: #b71c1c;
  }
  
  .add-round-btn {
    background-color: #6200ea;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-top: 10px;
    transition: background-color 0.3s;
  }
  
  .add-round-btn:hover {
    background-color: #3700b3;
  }
  /* Image Crop Styles */
  .ReactCrop {
    width: 100%;
    max-height: 400px;
    margin-top: 10px;
  }
  
  @media (max-width: 768px) {
    .modal {
      width: 100%;
    }
  }
  