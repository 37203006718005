/* Content container with expanded sidebar */
.content-container {
    transition: margin-left 0.3s ease, width 0.3s ease;
    padding: 20px;
    margin-left: 250px; /* When sidebar is expanded */
    width: calc(100% - 250px); /* Width when sidebar is expanded */
  }
  
  /* Content container when sidebar is collapsed */
  .sidebar-closed .content-container {
    margin-left: 80px; /* Shift to left when sidebar is collapsed */
    width: calc(100% - 80px); /* Width when sidebar is collapsed */
  }
  
  /* Navbar with expanded sidebar */
  .navbar {
    height: 60px;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 250px; /* Shift navbar to the right with expanded sidebar */
    width: calc(100% - 250px); /* Navbar width when sidebar is expanded */
    transition: left 0.3s ease, width 0.3s ease;
  }
  
  /* Navbar with collapsed sidebar */
  .sidebar-closed .navbar {
    left: 80px; /* Move navbar to the left when sidebar is collapsed */
    width: calc(100% - 80px); /* Navbar takes more space when sidebar is collapsed */
  }
  