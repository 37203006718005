/* Background settings */
.login-page {
  background: linear-gradient(135deg, #1e90ff 50%, #ffffff 50%); /* Diagonal blue and white */
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* Adjust the container to make the card wider and transparent */
.login-container {
  width: 100%;
  max-width: 600px;
  padding: 2rem;
}

/* Glass effect for the card */
.glass-card {
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Adds blur effect behind the card */
  border-radius: 1rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  color: white;
}

/* Styling for form inputs */
input[type="email"],
input[type="password"] {
  border-radius: 0 0.375rem 0.375rem 0;
}

.input-group-text {
  border-radius: 0.375rem 0 0 0.375rem;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 2rem;
  color: #fff; /* White text for better contrast */
}

.card-body {
  padding: 2rem;
}

/* Responsive design */
@media (max-width: 768px) {
  .login-container {
    padding: 1rem;
    max-width: 500px; /* Adjust for smaller screens */
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.75rem;
  }
  .login-container {
    max-width: 90%; /* Make the card almost full-width on small screens */
  }
}
