/* Navbar with fixed positioning */
.navbar {
    height: 60px;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; /* Full width of the viewport */
    transition: all 0.3s ease; /* Smooth transition */
  }
  
  /* When the sidebar is expanded, adjust the navbar */
  .sidebar-open + .navbar {
    left: 250px; /* Shift right by the width of the expanded sidebar */
    width: calc(100% - 250px); /* Reduced width to account for sidebar */
  }
  
  /* When the sidebar is collapsed, the navbar covers the full width */
  .sidebar-closed + .navbar {
    left: 80px; /* Adjust to the collapsed sidebar width */
    width: calc(100% - 80px); /* Full width minus the collapsed sidebar */
  }
  